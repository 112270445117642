import React, { useState } from "react";
import uploadIcon from "../../assets/uploadIcon.svg";
import DownloadModal from "./DownloadModal";
import UploadPropertiesModal from "./UploadPropertiesModal";
import SuccessAlert from "../../SuccessAlert";
import GeneralProperties from "./GeneralProperties";
import SpecificProperties from "./SpecificProperties";
import FunctionFilter from "./FunctionFilter";
import DateFilter from "./DateFilter";

import "./Properties.css";

const Properties = () => {
  const [activeTab, setActiveTab] = useState("General Properties");
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [successNotification, setSuccessNotification] = useState("");
  const [filters, setFilters] = useState({});

  const handleFilterApply = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleOpenModal = (option) => {
    if (option === "Download") {
      setDownloadModalOpen(true);
    } else if (option === "Upload") {
      setUploadModalOpen(true);
    }
  };

  const handleCloseModal = (option) => {
    if (option === "Download") {
      setDownloadModalOpen(false);
    } else if (option === "Upload") {
      setUploadModalOpen(false);
    }
  };

  const handleUploadSuccess = (message) => {
    setSuccessNotification(message);
    setTimeout(() => setSuccessNotification(""), 5000);
  };

  return (
    <div className="functions-container">
      {successNotification && (
        <SuccessAlert
          message={successNotification}
          onClose={() => setSuccessNotification("")}
        />
      )}
      <div className="functions-header">
        <h1>Properties</h1>
        <button
          className="upload-button-properties"
          onClick={() => handleOpenModal("Upload")}
        >
          <img src={uploadIcon} className="upload-icon" alt="upload icon" />
          Upload Bulk Properties
        </button>
      </div>
      <div className="functions-buttons">
        <div className="props-toggle-buttons">
          <button
            className={`props-toggle-button ${
              activeTab === "General Properties" ? "active" : ""
            }`}
            onClick={() => handleTabClick("General Properties")}
          >
            General Properties
          </button>
          <button
            className={`props-toggle-button ${
              activeTab === "Specific Properties" ? "active" : ""
            }`}
            onClick={() => handleTabClick("Specific Properties")}
          >
            Specific Properties
          </button>
        </div>

        {/* New container for dropdown and button */}
        <div className="functions-right-container">
          {activeTab === "Specific Properties" && (
            <>
              <DateFilter
                onApply={(start_time, end_time) => {
                  handleFilterApply("start_time", start_time);
                  handleFilterApply("end_time", end_time);
                }}
              />
              <FunctionFilter
                onApply={(option) =>
                  handleFilterApply("function_id", option.value)
                }
              />
            </>
          )}
          <button
            className="filter-button"
            onClick={() => handleOpenModal("Download")}
          >
            Download Properties
          </button>
        </div>
      </div>
      {activeTab === "General Properties" ? (
        <GeneralProperties />
      ) : (
        <SpecificProperties filters={filters} />
      )}
      <DownloadModal
        filters={filters}
        isOpen={isDownloadModalOpen}
        onClose={() => handleCloseModal("Download")}
      />
      <UploadPropertiesModal
        isOpen={isUploadModalOpen}
        onClose={() => handleCloseModal("Upload")}
        onSuccess={handleUploadSuccess}
      />
    </div>
  );
};

export default Properties;
