import React from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import downloadIcon from "../../assets/downloadIcon.svg";
import { Link } from "react-router-dom";

const DownloadModal = ({ isOpen, onClose }) => {
  return (
    <div className={`download-modal ${isOpen ? "open" : ""}`}>
      <div className="download-modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={cancelIcon} alt="cancel icon" onClick={onClose} />
        </button>
        <h2 className="download-title">Download</h2>
        <hr className="download-separator" />
        <div className="file-download-section">
          <div className="file-download-item">
            <span className="file-name">Metrics.xlsx</span>
            <Link
              className="download-icon-button"
              download
              to="https://mvd-cms-api.nawatech.co/api/sma/prompt_metrics/get/10/100?size=1&is_export=true"
              target="_blank"
            >
              <img src={downloadIcon} alt="download icon" />
            </Link>
          </div>
        </div>
        {/* <div className="download-all-section">
          <button className="download-all-button">Download All</button>
        </div> */}
      </div>
    </div>
  );
};

export default DownloadModal;
