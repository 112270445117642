import React, { useState } from "react";
// import cancelIcon from "../../assets/cancelIcon.svg";
import warningIcon from "../../assets/warningIcon.svg";
import axios from "axios";

const UploadPropertiesModal = ({ isOpen, onClose, onSuccess }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [file, setFile] = useState(null);

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_properties/bulk_update`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then(
      (response) => {
        const {
          data: { code, message },
        } = response;

        if (code !== 200) {
          console.log("Error uploading file: ", response);
          return;
        }

        console.log("File uploaded successfully: ", message);

        onSuccess(message);
        handleCancel();
      },
      (error) => {
        console.log("Error uploading file: ", error);
      }
    );
  };

  const handleFileChange = (e) => {
    const selectedFileName = e.target.files[0]?.name || "No file chosen";
    // Add file into state
    setFile(e.target.files[0]);
    setFileName(selectedFileName);
  };

  const handleSubmit = () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    setIsSubmitted(true);
  };

  const handleFinalSubmit = () => {
    uploadFile(file);
  };

  const handleCancel = () => {
    setIsSubmitted(false);
    // Reset file and file name
    setFileName("No file chosen");
    setFile("");
    // Close modal
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="upload-modal-overlay">
      <div className="upload-modal-content">
        {isSubmitted ? (
          <>
            <h2 className="upload-modal-title">Upload Bulk Confirmation</h2>
            <hr className="upload-modal-separator" />
            <p className="confirmation-text" style={{ textAlign: "center" }}>
              Do you really wish to submit this file?
            </p>
            <p className="confirmation-subtext" style={{ textAlign: "center" }}>
              Once you submit your file, multiple data based on your uploaded
              file will be added to the table.
            </p>
            <div className="upload-modal-buttons">
              <button
                className="upload-modal-button cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="upload-modal-button submit-button"
                onClick={handleFinalSubmit}
              >
                Submit
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="upload-modal-title">Upload Bulk Propertiess</h2>
            <hr className="upload-modal-separator" />
            <div className="file-upload-section">
              <label htmlFor="file-upload" className="file-upload-label">
                Upload Excel *
              </label>
              <div className="file-upload-input-container">
                <input
                  type="text"
                  id="file-name"
                  className="file-upload-input"
                  value={fileName}
                  readOnly
                />
                <input
                  type="file"
                  id="file-upload"
                  className="file-upload-hidden-input"
                  onChange={handleFileChange}
                />
                <button
                  className="file-upload-button"
                  onClick={() => document.getElementById("file-upload").click()}
                >
                  Select File
                </button>
              </div>
              <p className="file-upload-warning">
                <img src={warningIcon} alt="warning icon" />
                supported file excel max. 2MB
              </p>
            </div>
            <div className="upload-modal-buttons">
              <button
                className="upload-modal-button cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="upload-modal-button submit-button"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadPropertiesModal;
