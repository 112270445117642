import React, { useState } from "react";
import DatePicker from "react-datepicker";

import calendarIcon from "../../assets/calendarIcon.svg";

import "react-datepicker/dist/react-datepicker.css";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button className="date-button" onClick={onClick} ref={ref}>
    <img src={calendarIcon} alt="calendar icon" className="date-button-icon" />
    {value || "Choose Date"}
  </button>
));

const DateFilter = ({ onApply }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleApply = () => {
    onApply(startDate, endDate);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    onApply(null, null);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      customInput={<CustomInput />}
      className="date-range-picker"
      calendarContainer={({ children }) => (
        <div className="custom-calendar">
          {children}
          <div className="calendar-footer">
            <button onClick={handleClear} className="calendar-clear-button">
              Clear
            </button>
            <button onClick={handleApply} className="calendar-apply-button">
              Apply
            </button>
          </div>
        </div>
      )}
      shouldCloseOnSelect={false}
    />
  );
};

export default DateFilter;
