import React, { useEffect, useState } from "react";
import SuccessAlert from "../../SuccessAlert";
import axios from "axios";

const EditSpecificProp = ({ isOpen, onClose, formData }) => {
  const [formSpecificProp, setFormSpecificProp] = useState({});
  const [successNotification, setSuccessNotification] = useState("");

  const handleFormChange = (name, value) => {
    setFormSpecificProp((prev) => ({ ...prev, [name]: value }));
  };

  const updateDataSpecificProp = (data) => {
    axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_properties/update`,
      params: data,
    }).then(
      (response) => {
        const {
          data: { code, message },
        } = response;

        if (code !== 200) {
          console.log("Error updating function: ", response);
          return;
        }

        handleUpdateSuccess(message);
        onClose();
      },
      (error) => {
        console.log("Error fetching function details: ", error);
      }
    );
  };

  const handleUpdateSuccess = (message) => {
    setSuccessNotification(message);
    setTimeout(() => setSuccessNotification(""), 5000);
  };

  useEffect(() => {
    if (isOpen) {
      setFormSpecificProp(formData);
    } else {
      // Reset form data when modal is closed
      setFormSpecificProp({});
    }
  }, [isOpen, formData]);

  return (
    <>
      {successNotification && (
        <SuccessAlert
          message={successNotification}
          onClose={() => setSuccessNotification("")}
        />
      )}
      <div className={`edit-modal ${isOpen ? "open" : ""}`}>
        <div className="edit-modal-content">
          <h2 className="edit-title">Edit Properties</h2>
          <hr className="edit-separator" />
          <div className="edit-input-box">
            <h3 className="edit-subtitle">Specific Properties</h3>
            <div className="related-functions">
              <h4>Related Function</h4>
              <div className="function-boxes">
                {formSpecificProp.relatedFunctions?.map((func, index) => (
                  <div key={index} className="function-box">
                    {func.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="immutable-fields">
              <div className="field-group">
                <label>Properties Name</label>
                <input type="text" value={formSpecificProp.name} readOnly />
              </div>
              <div className="field-group">
                <label>Properties Type</label>
                <input type="text" value={formSpecificProp.type} readOnly />
              </div>
              <div className="field-group">
                <label>Mandatory Properties</label>
                <input
                  type="text"
                  value={formSpecificProp.isRequired ? "Yes" : "No"}
                  readOnly
                />
              </div>
            </div>
            <div className="editable-field">
              <label>Properties Description</label>
              <textarea
                onChange={(e) =>
                  handleFormChange("description", e.target.value)
                }
                rows={5}
                value={formSpecificProp.description}
              />
            </div>
          </div>
          <div className="edit-buttons">
            <button className="edit-button cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button
              className="edit-button submit-button"
              onClick={() =>
                updateDataSpecificProp({
                  id: formSpecificProp.id,
                  description: formSpecificProp.description,
                })
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSpecificProp;
