import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import calendarIcon from "../../assets/rqCalendarIcon.svg";
import filterIcon from "../../assets/filtersIcon.svg";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import openModalIcon from "../../assets/openModal.svg";
import redirectIcon from "../../assets/redirectIcon.svg";
import RQLineChart from "./RQLineChart";
import RQDonutChart from "./RQDonutChart";
import sampleData from "./sampleData";
import "./ResponseQuality.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ResponseQuality = () => {
  const [data, setData] = useState(sampleData);
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/fallback-management");
  };

  return (
    <div className="response-quality-container">
      <div className="rq-header-section">
        <h1 className="rq-header-title">Response Quality</h1>
        <div className="rq-button-group">
          <button className="rq-date-button">
            <img
              src={calendarIcon}
              alt="calendar icon"
              className="date-button-icon"
            />
            September 2024
          </button>
          <button className="rq-filter-button">
            <img
              src={filterIcon}
              alt="calendar icon"
              className="filter-button-icon"
            />
            Filter
          </button>
        </div>
      </div>
      <div className="rq-overview-section">
        <h2 className="rq-overview-title">Overview</h2>
        <div className="rq-overview-boxes">
          <div className="rq-overview-box">
            <p className="box-title">
              Success Rate
              <img
                src={tooltipIcon}
                alt="tooltip icon"
                className="tooltip-icon"
              />
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Error/Fallback Rate
              <img
                src={tooltipIcon}
                alt="tooltip icon"
                className="tooltip-icon"
              />
              <img
                src={redirectIcon}
                alt="redirect icon"
                className="redirect-icon"
                onClick={handleRedirect}
              />{" "}
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Satisfaction Rate
              <img
                src={tooltipIcon}
                alt="tooltip icon"
                className="tooltip-icon"
              />
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Avg. User Rating
              <img
                src={tooltipIcon}
                alt="tooltip icon"
                className="tooltip-icon"
              />
              <img
                src={openModalIcon}
                alt="open modal icon"
                className="redirect-icon"
              />{" "}
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Avg. Response Time
              <img
                src={tooltipIcon}
                alt="tooltip icon"
                className="tooltip-icon"
              />
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
        </div>
      </div>
      <div className="rq-charts-section">
        <RQLineChart />
        <RQDonutChart />
      </div>

      <div className="rq-table">
        <table>
          <thead>
            <tr>
              <th>Function</th>
              <th>Response Accuracy (%)</th>
              <th>Rating</th>
              <th>Total Thumbs Up</th>
              <th>Total Thumbs Down</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.function}</td>
                <td>{item.response_accuracy}</td>
                <td>{item.rating}</td>
                <td>{item.total_thumbs_up}</td>
                <td>{item.total_thumbs_down}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResponseQuality;
