import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import backArrow from "../../assets/backArrow.svg";
import EditSpecificProp from "./EditSpecificProp";

import axios from "axios";

const SpecificPropertiesDetail = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [dataSpecificProp, setDataSpecificProp] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const generalPropId = location.state?.id || "";

  const handleOpenModal = () => {
    setIsEditModalOpen(true);
  };

  const getDataSpecificProp = useCallback((params) => {
    // API call to get function details
    axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_properties/get/detail/`,
      params: params,
    }).then(
      (response) => {
        const {
          data: {
            code,
            data: { properties: dataProp, function: dataFunction },
          },
        } = response;

        if (code !== 200) {
          console.log("Error fetching function details: ", response);
          return;
        }

        setDataSpecificProp({ ...dataProp, relatedFunctions: [dataFunction] });
      },
      (error) => {
        console.log("Error fetching function details: ", error);
      }
    );
  }, []);

  useEffect(() => {
    getDataSpecificProp({ id: generalPropId });
  }, [generalPropId, getDataSpecificProp]);

  return (
    <div className="general-prop-details">
      <div className="general-prop-details-header">
        <img
          src={backArrow}
          className="back-button"
          alt="return button"
          onClick={() => navigate(-1)}
        />
        <h2 className="general-prop-details-title">
          Specific Properties Details
        </h2>
        <button className="general-prop-edit-button" onClick={handleOpenModal}>
          Edit Properties
        </button>
      </div>
      <div className="general-prop-overview-container">
        <h3 className="overview-title">Overview</h3>
        <div className="general-prop-container">
          <div className="overview-box general-prop-name">
            <p className="overview-box-title">Properties Name:</p>
            <hr className="overview-separator" />
            <p className="overview-description">{dataSpecificProp.name}</p>
          </div>
          <div className="overview-box general-prop-name">
            <p className="overview-box-title">Properties Type:</p>
            <hr className="overview-separator" />
            <p className="overview-description">{dataSpecificProp.type}</p>
          </div>
          <div className="overview-box general-prop-name">
            <p className="overview-box-title">Mandatory Properties:</p>
            <hr className="overview-separator" />
            <p className="overview-description">
              {dataSpecificProp.isrequired ? "Yes" : "No"}
            </p>
          </div>
          <div className="overview-box description">
            <p className="overview-box-title">Description:</p>
            <hr className="overview-separator" />
            <p className="overview-description">
              {dataSpecificProp.description}
            </p>
          </div>
        </div>
      </div>
      <div className="overview-container related-functions-container">
        <h3 className="related-functions-title">Related Functions</h3>
        <ul className="related-functions-list">
          {dataSpecificProp.relatedFunctions?.map((func, index) => (
            <li key={index}>{func.name}</li>
          ))}
        </ul>
      </div>
      <EditSpecificProp
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        formData={dataSpecificProp}
      />
    </div>
  );
};

export default SpecificPropertiesDetail;
