import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import hamburgerMenu from "./assets/hamburgerMenu.svg";
import sinarmasLogo from "./assets/sinarmasLogo.svg";
import signoutIcon from "./assets/signoutIcon.svg";
import userIcon from "./assets/userIcon.jpg";
import "./App.css";

import { ReactComponent as DashboardIcon } from "./assets/dashboardIcon.svg";
import { ReactComponent as FunctionIcon } from "./assets/functionMgmtIcon.svg";
import { ReactComponent as FallbackIcon } from "./assets/fallbackMgmtIcon.svg";
import { ReactComponent as UserRoleIcon } from "./assets/userroleIcon.svg";
import { ReactComponent as FallbackReportIcon } from "./assets/fallbackReportIcon.svg";
import { ReactComponent as DropDownIcon } from "./assets/dropdownIcon.svg";
import { ReactComponent as BulkTestIcon } from "./assets/bulkTestIcon.svg";

import FallbackManagement from "./FallbackMgmt/FallbackManagement";
import FallbackDetails from "./FallbackMgmt/FallbackDetails";
import Functions from "./PromptMgmt/Functions/Functions"
import FunctionDetails from "./PromptMgmt/Functions/FunctionDetails";
import Metrics from "./PromptMgmt/Metrics/Metrics";
import MetricDetails from "./PromptMgmt/Metrics/MetricDetails";
import Properties from "./PromptMgmt/Properties/Properties";
import InDevelopment from "./InDevelopment/InDevelopment";
import GeneralPropertiesDetail from "./PromptMgmt/Properties/GeneralPropertiesDetail";
import SpecificPropertiesDetail from "./PromptMgmt/Properties/SpecificPropertiesDetail";
import ResponseQuality from "./AnalyticsDashboard/ResponseQuality/ResponseQuality";

const WithRouter = ({ children }) => {
  return <Router>{children}</Router>;
};

const App = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [openDropdown, setOpenDropdown] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathToTitle = {
      "/fallback-management": "Fallback Management",
      "/fallback-management/details": "Fallback Management",
      "/prompt-management": "Prompt Management",
      "/prompt-management/functions": "Functions",
      "/prompt-management/functions/details": "Functions",
      "/prompt-management/properties": "Properties",
      "/prompt-management/properties/general-properties-details": "Properties",
      "/prompt-management/properties/specific-properties-details": "Properties",
      "/prompt-management/metrics": "Metrics",
      "/prompt-management/metrics/details": "Metrics",
      "/analytics-dashboard": "Analytics Dashboard",
      "/analytics-dashboard/user-engagement": "User Engagement",
      "/analytics-dashboard/response-quality": "Response Quality",
      "/analytics-dashboard/token-cost-consumption": "Token/Cost Consumption",
      "/user-role-management": "User & Role Management",
      "/fallback-report": "Fallback Report",
      "/bulk-testing": "Bulk Testing",
    };

    setActiveTab(pathToTitle[location.pathname] || "Fallback Management");

    if (location.pathname === "/") {
      navigate("/fallback-management", { replace: true });
    }
  }, [location.pathname, navigate]);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleTabClick = (tabTitle, path) => {
    setActiveTab(tabTitle);
    navigate(path);
  };

  const toggleDropdown = (tabTitle) => {
    setOpenDropdown(openDropdown === tabTitle ? null : tabTitle);
  };

  const renderTabs = () => {
    const tabs = [
      {
        title: "Fallback Management",
        icon: <FallbackIcon className="tab-icon" />,
        path: "/fallback-management",
      },
      {
        title: "Prompt Management",
        icon: <FunctionIcon className="tab-icon" />,
        path: "/prompt-management",
        hasDropdown: true,
        dropdownTabs: [
          { title: "Functions", path: "/prompt-management/functions" },
          { title: "Properties", path: "/prompt-management/properties" },
          { title: "Metrics", path: "/prompt-management/metrics" },
        ],
      },
      {
        title: "Analytics Dashboard",
        icon: <DashboardIcon className="tab-icon fill" />,
        path: "/analytics-dashboard",
        hasDropdown: true,
        dropdownTabs: [
          { title: "User Engagement", path: "/analytics-dashboard/user-engagement" },
          { title: "Response Quality", path: "/analytics-dashboard/response-quality" },
          { title: "Token/Cost Consumption", path: "/analytics-dashboard/token-cost-consumption" },
        ],
      },
      {
        title: "User & Role Management",
        icon: <UserRoleIcon className="tab-icon" />,
        path: "/user-role-management",
      },
      {
        title: "Fallback Report",
        icon: <FallbackReportIcon className="tab-icon" />,
        path: "/fallback-report",
      },
      {
        title: "Bulk Testing",
        icon: <BulkTestIcon className="tab-icon" />,
        path: "/bulk-testing",
      },
    ];
  
    return tabs.map((tab) => (
      <div key={tab.title} className="tab-wrapper">
        <div
          className={`tab ${activeTab === tab.title ? "active" : ""}`}
          onClick={() =>
            tab.hasDropdown ? toggleDropdown(tab.title) : handleTabClick(tab.title, tab.path)
          }
        >
          <div className="tab-content">
            {tab.icon}
            {isSidePanelOpen && <p className="tab-name">{tab.title}</p>}
          </div>
          {tab.hasDropdown && (
            <DropDownIcon
              className={`dropdown-icon ${openDropdown === tab.title ? "rotate" : ""}`}
            />
          )}
        </div>
        {tab.hasDropdown && openDropdown === tab.title && (
          <div className="dropdown">
            {tab.dropdownTabs.map((subTab) => (
              <div
                key={subTab.title}
                className={`tab ${activeTab === subTab.title ? "active" : ""}`}
                onClick={() => handleTabClick(subTab.title, subTab.path)}
              >
                {isSidePanelOpen && <p className="sub-tab-name">{subTab.title}</p>}
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };  

  const renderBottomTabs = () => {
    return (
      <div className={`bottom-tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
        <div className="bottom-tab" onClick={() => alert("User Profile")}>
          <img src={userIcon} className="user-icon" alt="User Icon" />
          {isSidePanelOpen && <p className="tab-name">User</p>}
        </div>
        <div className="bottom-tab" onClick={() => alert("Sign Out")}>
          <img src={signoutIcon} className="tab-icon" alt="Signout Icon" />
          {isSidePanelOpen && <p className="sign-out">Sign Out</p>}
        </div>
      </div>
    );
  };

  return (
    <div className="main-container">
      <div className={`side-panel ${isSidePanelOpen ? "open" : "closed"}`}>
        <img
          src={isSidePanelOpen ? sinarmasLogo : hamburgerMenu}
          className="logo"
          onClick={toggleSidePanel}
          alt="sinarmas"
        />
        {!isSidePanelOpen && <div className="separator"></div>}
        <div className={`tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
          {renderTabs()}
        </div>
        {/* {renderBottomTabs()} */}
      </div>
      <div className={`content-area ${isSidePanelOpen ? "shifted" : ""}`}>
        <Routes>
          <Route path="/fallback-management" element={<FallbackManagement />} />
          <Route path="/fallback-management/details" element={<FallbackDetails />} />
          {/* <Route path="/prompt-management" element={<InDevelopment />} /> */}
          <Route path="/prompt-management/functions" element={<Functions />} />
          <Route path="/prompt-management/functions/details" element={<FunctionDetails />} />
          <Route path="/prompt-management/properties" element={<Properties />} />
          <Route path="/prompt-management/properties/general-properties-details" element={<GeneralPropertiesDetail />} />
          <Route path="/prompt-management/properties/specific-properties-details" element={<SpecificPropertiesDetail />} />
          <Route path="/prompt-management/metrics" element={<Metrics />} />
          <Route path="/prompt-management/metrics/details" element={<MetricDetails />} />
          {/* <Route path="/analytics-dashboard" element={<InDevelopment />} /> */}
          <Route path="/analytics-dashboard/user-engagement" element={<InDevelopment />} />
          <Route path="/analytics-dashboard/response-quality" element={<ResponseQuality />} />
          <Route path="/analytics-dashboard/token-cost-consumption" element={<InDevelopment />} />
          <Route path="/user-role-management" element={<InDevelopment />} />
          <Route path="/fallback-report" element={<InDevelopment />} />
          <Route path="/bulk-testing" element={<InDevelopment />} />
        </Routes>
      </div>
    </div>
  );
};

export default () => (
  <WithRouter>
    <App />
  </WithRouter>
);
