import React from "react";
import { Doughnut } from "react-chartjs-2";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import openModalIcon from "../../assets/openModal.svg";
import "./ResponseQuality.css";

const RQDonutChart = () => {
  const data = {
    labels: ["Thumbs Up", "Thumbs Down"],
    datasets: [
      {
        data: [440, 60],
        backgroundColor: ["#33D616", "#F77B65"],
        hoverBackgroundColor: ["#33D6164D", "#F77B654D"],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const totalVotes = 440 + 60;
  const thumbsUpValue = 440;
  const thumbsDownValue = 60;
  const thumbsUpPercentage = ((thumbsUpValue / totalVotes) * 100).toFixed(0);
  const thumbsDownPercentage = ((thumbsDownValue / totalVotes) * 100).toFixed(
    0
  );

  const legendData = [
    {
      label: "Thumbs Up",
      value: thumbsUpValue,
      percentage: thumbsUpPercentage,
      color: "#33D616",
    },
    {
      label: "Thumbs Down",
      value: thumbsDownValue,
      percentage: thumbsDownPercentage,
      color: "#F77B65",
    },
  ];

  return (
    <div className="rq-donut-container">
      <h1 className="rq-donut-title">
        Total User Satisfaction
        <img
          src={tooltipIcon}
          alt="tooltip icon"
          className="tooltip-icon"
        />
        <img
          src={openModalIcon}
          alt="redirect icon"
          className="rq-open-modal"
        />
      </h1>
      <div className="rq-chart-and-legend">
        <Doughnut className="rq-donut" data={data} options={chartOptions} />
        <div className="rq-legend-container">
          {legendData.map((item, index) => (
            <div key={index} className="rq-legend-item">
              <div className="rq-legend-info">
                <div
                  className="rq-legend-color-box"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span style={{ marginRight: "10px" }}>{item.label}</span>
              </div>
              <div className="rq-legend-value">
                <span className="rq-value">{item.value}</span>
                <div className="rq-percentage-bar">
                  <div
                    className="rq-percentage-fill"
                    style={{
                      width: item.percentage + "%",
                      backgroundColor: item.color,
                    }}
                  ></div>
                </div>
                <span className="rq-percentage-text">{item.percentage}%</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RQDonutChart;
