import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Alert } from "../../components";

const EditModal = ({ isOpen, onClose, onSuccess, formData }) => {
  const [formMetric, setFormMetric] = useState({});

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleFormChange = (name, value) => {
    setFormMetric((prev) => ({ ...prev, [name]: value }));
  };

  const updateDataMetric = (data) => {
    axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_metrics/update`,
      params: data,
    }).then(
      (response) => {
        const {
          data: { code, message, detail },
        } = response;

        if (code !== 200) {
          showAlert({
            type: "error",
            title: code || "Error",
            message: detail || "Error updating data",
          });
          return;
        }

        showAlert({
          type: "success",
          message: message,
        });
        onSuccess();
      },
      (error) => {
        const { message, response: { data: { detail } = {} } = {} } = error;

        showAlert({
          type: "error",
          title: message || "Error",
          message: detail || "Error updating data",
        });
      }
    );
  };

  useEffect(() => {
    if (isOpen) {
      setFormMetric(formData ? formData : {});
    } else {
      // Reset form data when modal is closed
      setFormMetric({});
    }
  }, [isOpen, formData]);

  const handleSave = () => {
    updateDataMetric(formMetric);
  };

  return (
    <>
      <Alert ref={alertRef} />

      <div className={`edit-modal ${isOpen ? "open" : ""}`}>
        <div className="edit-modal-content">
          <h2 className="edit-title">Edit Metric</h2>
          <hr className="edit-separator" />
          <div className="edit-section">
            <div className="edit-item">
              <span className="edit-name">Metric Name</span>
              <input
                className="edit-input"
                value={formMetric.metrics_name}
                onChange={(e) =>
                  handleFormChange("metrics_name", e.target.value)
                }
                readOnly={!!formMetric.metrics_name}
                placeholder="Enter metric name"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Unit</span>
              <input
                className="edit-input"
                value={formMetric.unit}
                onChange={(e) => handleFormChange("unit", e.target.value)}
                readOnly={!!formMetric.unit}
                placeholder="Enter unit"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Location Granularity</span>
              <input
                className="edit-input"
                value={formMetric.location_granuality}
                onChange={(e) =>
                  handleFormChange("location_granuality", e.target.value)
                }
                readOnly={!!formMetric.location_granuality}
                placeholder="Enter location granularity"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Period Granularity</span>
              <input
                className="edit-input"
                value={formMetric.period_granuality}
                onChange={(e) =>
                  handleFormChange("period_granuality", e.target.value)
                }
                readOnly={!!formMetric.period_granuality}
                placeholder="Enter period granularity"
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Dashboard Type</span>
              <input
                className="edit-input"
                value={formMetric.dashboard_type}
                onChange={(e) =>
                  handleFormChange("metrics_name", e.target.value)
                }
                readOnly={!!formMetric.dashboard_type}
                placeholder=""
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Description</span>
              <textarea
                className="edit-input edit-description"
                placeholder="Edit the description"
                value={formMetric.description}
                onChange={(e) =>
                  handleFormChange("description", e.target.value)
                }
                rows={4}
              />
            </div>
          </div>
          <div className="edit-buttons">
            <button className="edit-button cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button className="edit-button submit-button" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditModal;
