import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import sortIcon from "../../assets/sortIcon.svg";
import kebabMenu from "../../assets/kebabMenu.svg";
import EditSpecificProp from "./EditSpecificProp";
import Pagination from "../../components/Pagination";
import { ReactComponent as DetailsIcon } from "../../assets/detailsEye.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";

const SpecificProperties = ({ filters }) => {
  const [dataSpecificProps, setDataSpecificProps] = useState([]);
  const [dataSpecificProp, setDataSpecificProp] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [successNotification, setSuccessNotification] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  const navigate = useNavigate();

  const handleOpenModal = (item) => {
    // setSelectedItem(item);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    // setSelectedItem(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleMenuClick = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const handleDetailsClick = (item) => {
    navigate("/prompt-management/properties/general-properties-details", {
      state: { id: item.id },
    });
  };

  const fetchDataSpecificProps = useCallback((page, size, params) => {
    axios({
      method: "get",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_properties/get-spesific/${size}/${page}`,
      params: params,
    })
      .then((response) => {
        const {
          data: {
            data: { result, total_page },
            code,
          },
        } = response;

        if (code !== 200) {
          console.log("Error fetching data: ", response);
          return;
        }

        setDataSpecificProps(result || []);
        setTotalPages(total_page || 1);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getDataSpecificProp = useCallback((params) => {
    // API call to get function details
    return axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_properties/get/detail/`,
      params: params,
    });
  }, []);

  const handleEditClick = async (item) => {
    try {
      const response = await getDataSpecificProp({ id: item.id });
      const {
        data: {
          code,
          data: { properties: dataProp, function: dataFunction },
        },
      } = response;

      if (code !== 200) {
        console.log("Error fetching function details: ", response);
        return;
      }

      setDataSpecificProp({ ...dataProp, relatedFunctions: [dataFunction] });
      handleOpenModal("Edit");
    } catch (error) {
      console.log("Error fetching function details: ", error);
    }
  };

  useEffect(() => {
    fetchDataSpecificProps(currentPage, itemsPerPage, {
      size: itemsPerPage,
      is_export: false,
      ...filters,
    });
  }, [currentPage, itemsPerPage, filters, fetchDataSpecificProps]);

  return (
    <div className="functions-table">
      <table>
        <thead>
          <tr>
            <th>
              Properties Name
              <img
                src={sortIcon}
                className="sort-icon"
                alt="Sort icon"
                style={{ cursor: "pointer" }}
              />
            </th>
            <th>Properties Type</th>
            <th>Properties Description</th>
            <th>Mandatory Properties</th>
            <th>
              Last Update
              <img
                src={sortIcon}
                className="sort-icon"
                alt="Sort icon"
                style={{ cursor: "pointer" }}
              />
            </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {dataSpecificProps.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.type}</td>
              <td>
                {item.description.length > 150
                  ? item.description.slice(0, 150) + "..."
                  : item.description}
              </td>
              <td>{item.isrequired ? "Yes" : "No"}</td>
              <td>
                {format(new Date(item.updated_at), "yyyy-MM-dd hh:mm:ss.SSX")}
              </td>
              <td style={{ position: "relative" }}>
                <img
                  src={kebabMenu}
                  alt="kebab menu"
                  onClick={() => handleMenuClick(index)}
                  style={{ cursor: "pointer" }}
                />
                {activeMenu === index && (
                  <div className="functions-menu">
                    <div
                      className="functions-menu-option"
                      onClick={() => handleDetailsClick(item)}
                    >
                      <DetailsIcon className="functions-menu-icon" />
                      View Details
                    </div>
                    <div
                      className="functions-menu-option"
                      onClick={() => handleEditClick(item)}
                    >
                      <EditIcon className="functions-menu-icon" />
                      Edit
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
      <EditSpecificProp
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        formData={dataSpecificProp}
      />
    </div>
  );
};

export default SpecificProperties;
