import React, { useState, useEffect } from "react";
import calendarIcon from "../assets/calendarIcon.svg";
import filterIcon from "../assets/filterIcon.svg";
import exportIcon from "../assets/exportIcon.svg";
import Analytics from "./Analytics";
import Overview from "./Overview";
import FilterModal from "./FilterModal";
import AnalyticsFilterModal from "./AnalyticsFilterModal";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import "./FallbackManagement.css";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button className="date-button" onClick={onClick} ref={ref}>
    <img src={calendarIcon} alt="calendar icon" className="date-button-icon" />
    {value || "Choose Date"}
  </button>
));

const FallbackManagement = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAnalyticsFilterModalOpen, setIsAnalyticsFilterModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    detectedCapability: null,
    selectedCapability: null
  });
  const [selectedAnalyticsFilters, setSelectedAnalyticsFilters] = useState({
    detectedCapability: null,
    month: null,
    year: null,
    metrics: null,
    psm: null,
    region: null,
    estate: null,
  });

  useEffect(() => {
  }, [startDate, endDate, selectedFilters, selectedAnalyticsFilters]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseModal = () => {
    if (activeTab === 'Overview') {
      setIsFilterModalOpen(false);
    } else if (activeTab === 'Analytics') {
      setIsAnalyticsFilterModalOpen(false);
    }
  };

  const handleFilterButtonClick = () => {
    if (activeTab === 'Overview') {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else if (activeTab === 'Analytics') {
      setIsAnalyticsFilterModalOpen(!isAnalyticsFilterModalOpen);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleApply = () => {
    console.log(`Selected Date Range: ${format(startDate, 'yyyy-MM-dd')} to ${format(endDate, 'yyyy-MM-dd')}`);
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters(filters);
    handleCloseModal();
  };

  const handleAnalyticsFilterApply = (filters) => {
    setSelectedAnalyticsFilters(filters);
    handleCloseModal();
  };

  const handleExportData = async () => {
    try {
      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
      const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

      const url = activeTab === 'Overview'
        ? `https://mvd-cms-api.nawatech.co/api/sma/fallback/get-data-gpt?capability=${selectedFilters.detectedCapability}&suggested_capability=${selectedFilters.selectedCapability}&start_time=${formattedStartDate}&end_time=${formattedEndDate}&page=1&size=100&is_export=true`
        : `https://mvd-cms-api.nawatech.co/api/sma/fallback/get-analytic-gpt?detected_capability=${selectedAnalyticsFilters.detectedCapability}&year=${selectedAnalyticsFilters.year}&psm=${selectedAnalyticsFilters.psm}&estate=${selectedAnalyticsFilters.estate}&month=${selectedAnalyticsFilters.month}&metrics=${selectedAnalyticsFilters.metrics}&region=${selectedAnalyticsFilters.region}&page=1&size=100&is_export=true`;
  
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const contentDisposition = response.headers.get('content-disposition');
      let filename = 'fallback_management.xlsx';
      
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }
  
      const blob = await response.blob();
  
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;
  
      document.body.appendChild(link);
      link.click();
  
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <Overview startDate={startDate} endDate={endDate} filters={selectedFilters} />;
      case 'Analytics':
        return <Analytics filters={selectedAnalyticsFilters}/>;
      default:
        return null;
    }
  };

  return (
    <div className="fallback-management">
      <h1 className="page-title">Fallback Management</h1>
      <div className="top-controls">
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${activeTab === "Overview" ? "active" : ""}`}
            onClick={() => handleTabClick("Overview")}
          >
            Overview
          </button>
          <button
            className={`toggle-button ${activeTab === "Analytics" ? "active" : ""}`}
            onClick={() => handleTabClick("Analytics")}
          >
            Analytics
          </button>
        </div>
        <div className="action-buttons">
          {activeTab === "Overview" && (
            <>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={<CustomInput />}
                className="date-range-picker"
                calendarContainer={({ children }) => (
                  <div className="custom-calendar">
                    {children}
                    <div className="calendar-footer">
                      <button onClick={handleClear} className="calendar-clear-button">Clear</button>
                      <button onClick={handleApply} className="calendar-apply-button">Apply</button>
                    </div>
                  </div>
                )}
              />
              <button className="action-button" onClick={handleFilterButtonClick}>
                Filter
                <img src={filterIcon} className="filter-icon" alt="filter icon" />
              </button>
              <button className="action-button export" onClick={handleExportData}>
                <img src={exportIcon} className="export-icon" alt="export icon" />
                Export Data
              </button>
            </>
          )}
          {activeTab === "Analytics" && (
            <>
              <button className="action-button" onClick={handleFilterButtonClick}>
                Filter
                <img src={filterIcon} className="filter-icon" alt="filter icon" />
              </button>
            </>
          )}
        </div>
      </div>
      <div className="tab-content">{renderContent()}</div>
      <FilterModal isOpen={isFilterModalOpen} onClose={handleCloseModal} onApply={handleFilterApply} />
      <AnalyticsFilterModal isOpen={isAnalyticsFilterModalOpen} onClose={handleCloseModal} onApply={handleAnalyticsFilterApply} />
    </div>
  );
};

export default FallbackManagement;
