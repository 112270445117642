import React, { useEffect, useRef, useState } from "react";
import { Alert } from "../../components";

import trashIcon from "../../assets/trashIcon.svg";
import dangerIcon from "../../assets/dangerIcon.svg";

import axios from "axios";

const EditModal = ({ isOpen, onClose, onSuccess, formData }) => {
  const [formFunction, setFormFunction] = useState({});
  const [sampleQuestions, setSampleQuestions] = useState([]);
  const [deletedQuestions, setDeletedQuestions] = useState([]);

  const [deleteQuestionPrompt, setDeleteQuestionPrompt] = useState({});

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleAddQuestion = () => {
    setSampleQuestions((prev) => [...prev, { question: "" }]);
  };

  const handleChangeQuestion = (index, value) => {
    const updatedQuestions = sampleQuestions.map((question, i) =>
      i === index ? { ...question, question: value } : question
    );
    setSampleQuestions(updatedQuestions);
  };

  const handleDeleteQuestion = (index, question) => {
    // Remove question from sampleQuestions
    const updatedQuestions = sampleQuestions.filter((_, i) => i !== index);
    setSampleQuestions(updatedQuestions);
    // Add question to deletedQuestions
    setDeletedQuestions((prev) => [...prev, question]);
  };

  const handleChangeFuction = (name, value) => {
    setFormFunction((prev) => ({ ...prev, [name]: value }));
  };

  const updateDataFunction = (data) => {
    return axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_function/update/function`,
      params: data,
    });
  };

  const deleteBulkQuestion = (data) => {
    // const postData = new FormData();
    // postData.append("ids", data.ids);

    return axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_function/drop/sample-question`,
      data: data,
    });
  };

  const addBulkQuestion = (data) => {
    // const postData = new FormData();
    // postData.append("datas", data.datas);

    return axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_function/add/sample-question`,
      data: data,
    });
  };

  const handleSave = () => {
    // Get deleted question ids and new questions
    const deletedQuestionIds = deletedQuestions.map((question) => question.id);
    // const newQuestions = sampleQuestions
    //   // Filter out questions that already have an id (existing questions)
    //   .filter((question) => !question.id && question.question)
    //   // Map to new question object
    //   .map((question) => ({
    //     question: question.question,
    //     detected_capability: formFunction.name,
    //   }));

    // Update function, delete questions, and add new questions
    const promises = [
      updateDataFunction({
        id: formFunction.id,
        description: formFunction.description,
      }),
    ];

    if (deletedQuestionIds.length > 0) {
      promises.push(deleteBulkQuestion({ id: deletedQuestionIds }));
    }

    if (sampleQuestions.length > 0) {
      promises.push(
        addBulkQuestion({
          datas: sampleQuestions.map((question) => ({
            id: question.id ? question.id : null,
            question: question.question,
            capability_function: formFunction.name,
          })),
        })
      );
    }

    Promise.all(promises)
      .then((responses) => {
        const updateFunctionResponse = responses[0];
        const updateFunctionData = updateFunctionResponse.data;

        const deleteBulkQuestionData =
          deletedQuestionIds.length > 0 ? responses[1].data : { code: 200 };
        const addBulkQuestionData =
          sampleQuestions.length > 0
            ? responses[responses.length - 1].data
            : { code: 200 };

        if (
          updateFunctionData.code === 200 &&
          deleteBulkQuestionData.code === 200 &&
          addBulkQuestionData.code === 200
        ) {
          showAlert({
            type: "success",
            message: updateFunctionData.message,
          });
          onSuccess();
        } else {
          showAlert({
            type: "error",
            message: "Error updating data",
          });
        }
      })
      .catch((error) => {
        const { message, response: { data: { detail } = {} } = {} } = error;

        showAlert({
          type: "error",
          title: message,
          message: detail,
        });
      });
  };

  useEffect(() => {
    if (isOpen) {
      setFormFunction(formData?.function ? formData?.function : {});
      setSampleQuestions(
        formData?.sampleQuestions ? formData?.sampleQuestions : []
      );
      // Reset form data when modal is opened
      setDeletedQuestions([]);
    } else {
      // Reset form data when modal is closed
      setFormFunction({});
      setSampleQuestions([]);
      setDeletedQuestions([]);
    }
  }, [isOpen, formData]);

  return (
    <>
      <Alert ref={alertRef} />

      {deleteQuestionPrompt.question && (
        <>
          <div className="upload-modal-overlay">
            <div className="upload-modal-content">
              <img src={dangerIcon} alt="Warning" width={64} height={64} />
              <p className="confirmation-text" style={{ textAlign: "center" }}>
                Delete Sample Question?
              </p>
              <p
                className="confirmation-subtext"
                style={{ textAlign: "center" }}
              >
                Once you proceed, the sample question will be permanently
                deleted and this action cannot be undone.
              </p>
              <div className="upload-modal-buttons">
                <button
                  className="upload-modal-button cancel-button"
                  onClick={() => setDeleteQuestionPrompt({})}
                >
                  Cancel
                </button>
                <button
                  className="upload-modal-button submit-button"
                  onClick={() => {
                    handleDeleteQuestion(
                      deleteQuestionPrompt.index,
                      deleteQuestionPrompt.question
                    );
                    setDeleteQuestionPrompt({});
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={`edit-modal ${isOpen ? "open" : ""}`}>
        <div className="edit-modal-content">
          <h2 className="edit-title">Edit Function</h2>
          <hr className="edit-separator" />
          <div className="edit-section">
            <div className="edit-item">
              <span className="edit-name">Function Name</span>
              <input
                className="edit-input"
                placeholder="Function name"
                defaultValue={formFunction.name}
                disabled
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Description</span>
              <textarea
                className="edit-input edit-description"
                placeholder="PK Produced - “PK Produced” refers to the total quantity of palm kernel produced, measured in tons. This metric is for mill (not available for estate), showing the amount of palm kernel extracted from the nuts of the palm fruit at each mill."
                rows={4}
                value={formFunction.description}
                onChange={(e) =>
                  handleChangeFuction("description", e.target.value)
                }
              />
            </div>
            <div className="edit-item">
              <span className="edit-name">Sample Questions</span>
              <ul className="edit-sample-list">
                {sampleQuestions.map((question, index) => (
                  <li key={index} className="edit-sample-item">
                    <input
                      className="edit-input"
                      placeholder="Question"
                      value={question.question}
                      onChange={(e) =>
                        handleChangeQuestion(index, e.target.value)
                      }
                    />
                    {/* <span>{question.question}</span> */}
                    <button
                      className="delete-button"
                      onClick={() => {
                        setDeleteQuestionPrompt({ index, question });
                      }}
                    >
                      <img
                        src={trashIcon}
                        alt="Delete"
                        className="trash-icon"
                      />
                    </button>
                  </li>
                ))}
              </ul>
              <div className="add-more-container">
                <button className="add-more-button" onClick={handleAddQuestion}>
                  + Add New
                </button>
              </div>
            </div>
          </div>
          <div className="edit-buttons">
            <button className="edit-button cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button className="edit-button submit-button" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditModal;
