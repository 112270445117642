import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import uploadIcon from "../../assets/uploadIcon.svg";
import FiltersIcon from "../../assets/filtersIcon.svg";
import sortIcon from "../../assets/sortIcon.svg";
import kebabMenu from "../../assets/kebabMenu.svg";
import MetricsFilter from "./MetricsFilter";
import DownloadModal from "./DownloadModal";
import UploadMetricsModal from "./UploadMetricsModal";
import EditModal from "./EditModal";
import { Alert, Pagination } from "../../components";

import axios from "axios";
import { format } from "date-fns";

import { ReactComponent as DetailsIcon } from "../../assets/detailsEye.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";

import "./Metrics.css";

const Metrics = () => {
  const navigate = useNavigate();

  const [dataMetrics, setDataMetrics] = useState([]);
  const [dataMetric, setDataMetric] = useState({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters(filters);
    handleCloseModal("Filter");
  };

  const handleOpenModal = (option, item = null) => {
    if (option === "Filter") {
      setIsFilterModalOpen(true);
    } else if (option === "Download") {
      setDownloadModalOpen(true);
    } else if (option === "Upload") {
      setUploadModalOpen(true);
    } else if (option === "Edit") {
      setEditModalOpen(true);
    }
  };

  const handleCloseModal = (option) => {
    if (option === "Filter") {
      setIsFilterModalOpen(false);
    } else if (option === "Download") {
      setDownloadModalOpen(false);
    } else if (option === "Upload") {
      setUploadModalOpen(false);
    } else if (option === "Edit") {
      setEditModalOpen(false);
    }
  };

  const handleMenuClick = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
  };

  const handleDetailsClick = (item) => {
    navigate("/prompt-management/metrics/details", { state: { id: item.id } });
  };

  const handleUploadSuccess = (data) => {
    const { code, message, detail } = data;

    if (code !== 200) {
      showAlert({ type: "error", title: code, message: detail });
      return;
    }

    showAlert({ type: "success", message: message });
  };

  const handleUploadError = (error) => {
    const { message, response: { data: { detail } = {} } = {} } = error;

    showAlert({
      type: "error",
      title: message,
      message: detail,
    });
  };

  const fetchDataMetrics = useCallback((page, size, params) => {
    axios({
      method: "get",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_metrics/get/${size}/${page}`,
      params: params,
    })
      .then((response) => {
        const {
          data: {
            data: { result, total_page },
            code,
            detail,
          },
        } = response;

        if (code !== 200) {
          showAlert({
            type: "error",
            title: code || "Error",
            message: detail || "Error fetching data",
          });
          return;
        }

        setDataMetrics(result || []);
        setTotalPages(total_page || 1);
      })
      .catch((error) => {
        const { message, response: { data: { detail } = {} } = {} } = error;

        showAlert({
          type: "error",
          title: message || "Error",
          message: detail || "Error fetching data",
        });
      });
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDataMetric = useCallback((params) => {
    // API call to get function details
    return axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_metrics/get/detail/`,
      params: params,
    });
  }, []);

  const handleEditClick = async (item) => {
    try {
      const response = await getDataMetric({ id: item.id });
      const {
        data: {
          code,
          data: { metrics: metricData },
          detail,
        },
      } = response;

      if (code !== 200) {
        showAlert({
          type: "error",
          title: code || "Error",
          message: detail || "Error fetching data",
        });
        return;
      }

      setDataMetric(metricData);
      handleOpenModal("Edit");
    } catch (error) {
      const { message, response: { data: { detail } = {} } = {} } = error;

      showAlert({
        type: "error",
        title: message || "Error",
        message: detail || "Error fetching data",
      });
    }
  };

  useEffect(() => {
    fetchDataMetrics(currentPage, itemsPerPage, {
      ...selectedFilters,
      size: itemsPerPage,
      is_export: false,
    });
  }, [currentPage, itemsPerPage, selectedFilters, fetchDataMetrics]);

  return (
    <>
      <Alert ref={alertRef} />

      <div className="metrics-container">
        <div className="metrics-header">
          <h1>Metrics</h1>
          <button
            className="metrics-upload-button"
            onClick={() => handleOpenModal("Upload")}
          >
            <img src={uploadIcon} className="upload-icon" alt="upload icon" />
            Upload Bulk Metrics
          </button>
        </div>
        <div className="metrics-buttons">
          <button
            className="download-button"
            onClick={() => handleOpenModal("Download")}
          >
            Download Metrics
          </button>
          <button
            className="filter-button"
            onClick={() => handleOpenModal("Filter")}
          >
            <img
              src={FiltersIcon}
              className="filters-icon"
              alt="filters icon"
            />
            Filter
          </button>
        </div>
        <div className="metrics-table">
          <table>
            <thead>
              <tr>
                <th>
                  Metrics Name
                  <img
                    src={sortIcon}
                    className="sort-icon"
                    alt="Sort icon"
                    style={{ cursor: "pointer" }}
                  />
                </th>
                <th>Unit</th>
                <th>Location Granularity</th>
                <th>Period Granularity</th>
                <th>Dashboard Type</th>
                <th style={{ width: "400px" }}>Description</th>
                <th>
                  Last Updated
                  <img
                    src={sortIcon}
                    className="sort-icon"
                    alt="Sort icon"
                    style={{ cursor: "pointer" }}
                  />
                </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {dataMetrics.map((item, index) => {
                const isIncomplete = !(
                  item.metrics_name &&
                  item.unit &&
                  item.location_granuality &&
                  item.period_granuality &&
                  item.dashboard_type &&
                  item.description
                );

                return (
                  <tr key={index}>
                    <td>
                      {isIncomplete ? (
                        <span className="incomplete-label">*</span>
                      ) : (
                        ""
                      )}
                      {item.metrics_name}
                    </td>
                    <td>{item.unit}</td>
                    <td>{item.location_granuality}</td>
                    <td>{item.period_granuality}</td>
                    <td>{item.dashboard_type}</td>
                    <td>
                      {item.description.length > 150
                        ? item.description.slice(0, 150) + "..."
                        : item.description}
                    </td>
                    <td>
                      {format(
                        new Date(item.updated_at),
                        "yyyy-MM-dd hh:mm:ss.SSX"
                      )}
                    </td>
                    <td style={{ position: "relative" }}>
                      <img
                        src={kebabMenu}
                        alt="kebab menu"
                        onClick={() => handleMenuClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      {activeMenuIndex === index && (
                        <div className="metrics-menu">
                          <div
                            className="metrics-menu-option"
                            onClick={() => handleDetailsClick(item)}
                          >
                            <DetailsIcon className="metrics-menu-icon" />
                            View Details
                          </div>
                          <div
                            className="metrics-menu-option"
                            onClick={() => handleEditClick(item)}
                          >
                            <EditIcon className="metrics-menu-icon" />
                            Edit
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
        <MetricsFilter
          isOpen={isFilterModalOpen}
          onClose={() => handleCloseModal("Filter")}
          onApply={handleFilterApply}
        />
        <DownloadModal
          isOpen={isDownloadModalOpen}
          onClose={() => handleCloseModal("Download")}
        />
        <UploadMetricsModal
          isOpen={isUploadModalOpen}
          onClose={() => handleCloseModal("Upload")}
          onUploaded={handleUploadSuccess}
          onError={handleUploadError}
        />
        <EditModal
          isOpen={isEditModalOpen}
          onClose={() => handleCloseModal("Edit")}
          onSuccess={() => {
            handleCloseModal("Edit");
            fetchDataMetrics(currentPage, itemsPerPage, {
              ...selectedFilters,
              size: itemsPerPage,
              is_export: false,
            });
          }}
          formData={dataMetric}
        />
      </div>
    </>
  );
};

export default Metrics;
