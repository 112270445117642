import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import backArrow from "../../assets/backArrow.svg";
import EditGeneralProp from "./EditGeneralProp";

import axios from "axios";

// const relatedFunctions = [
//   "get_metrics_trend",
//   "get_metrics_combine",
//   "get_top_or_ranking",
//   "get_metrics_compare",
//   "get_metrics_trend",
//   "get_metrics_summary",
// ];

const GeneralPropertiesDetail = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [dataGeneralProp, setDataGeneralProp] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const generalPropId = location.state?.id || "";

  const handleOpenModal = () => {
    setIsEditModalOpen(true);
  };

  const getDataGeneralProp = useCallback((params) => {
    // API call to get function details
    axios({
      method: "post",
      baseURL: "https://mvd-cms-api.nawatech.co/api/sma",
      url: `prompt_properties/get/detail/`,
      params: params,
    }).then(
      (response) => {
        const {
          data: {
            code,
            data: { properties: dataProp, function: dataFunction },
          },
        } = response;

        if (code !== 200) {
          console.log("Error fetching function details: ", response);
          return;
        }

        setDataGeneralProp({ ...dataProp, relatedFunctions: [dataFunction] });
      },
      (error) => {
        console.log("Error fetching function details: ", error);
      }
    );
  }, []);

  useEffect(() => {
    getDataGeneralProp({ id: generalPropId });
  }, [generalPropId, getDataGeneralProp]);

  return (
    <div className="general-prop-details">
      <div className="general-prop-details-header">
        <img
          src={backArrow}
          className="back-button"
          alt="return button"
          onClick={() => navigate(-1)}
        />
        <h2 className="general-prop-details-title">
          General Properties Details
        </h2>
        <button className="general-prop-edit-button" onClick={handleOpenModal}>
          Edit Properties
        </button>
      </div>
      <div className="general-prop-overview-container">
        <h3 className="overview-title">Overview</h3>
        <div className="general-prop-container">
          <div className="overview-box general-prop-name">
            <p className="overview-box-title">Properties Name:</p>
            <hr className="overview-separator" />
            <p className="overview-description">{dataGeneralProp.name}</p>
          </div>
          <div className="overview-box general-prop-name">
            <p className="overview-box-title">Properties Type:</p>
            <hr className="overview-separator" />
            <p className="overview-description">{dataGeneralProp.type}</p>
          </div>
          <div className="overview-box general-prop-name">
            <p className="overview-box-title">Mandatory Properties:</p>
            <hr className="overview-separator" />
            <p className="overview-description">
              {dataGeneralProp.isrequired ? "Yes" : "No"}
            </p>
          </div>
          <div className="overview-box description">
            <p className="overview-box-title">Description:</p>
            <hr className="overview-separator" />
            <p className="overview-description">
              {dataGeneralProp.description}
            </p>
          </div>
        </div>
      </div>
      <div className="overview-container related-functions-container">
        <h3 className="related-functions-title">Related Functions</h3>
        <ul className="related-functions-list">
          {dataGeneralProp.relatedFunctions?.map((func, index) => (
            <li key={index}>{func.name}</li>
          ))}
        </ul>
      </div>
      <EditGeneralProp
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        formData={dataGeneralProp}
      />
    </div>
  );
};

export default GeneralPropertiesDetail;
